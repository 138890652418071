import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

export const FileUploadContainer = ({ children, className, ...props }) => {
  return (
    <section
      {...props}
      className={
        'relative flex flex-col align-center justify-center bg-white lg:border lg:bg-gray-50 lg:border-gray-300 lg:border-dashed ' +
        className
      }
    >
      {children}
    </section>
  )
}
// eslint-disable-next-line
export const FormField = forwardRef((props, ref) => {
  return (
    <input
      ref={ref}
      className={'w-full inset-0 opacity-0 block absolute focus:outline-none'}
      {...props}
    />
  )
})
export const InputLabel = ({ children, ...props }) => {
  return (
    <label className={'absolute left-0 -top-6 text-black'} {...props}>
      {children}
    </label>
  )
}

export const DragDropText = ({ children, ...props }) => {
  return (
    <p className={'hidden lg:block text-bold mt-0 text-center '} {...props}>
      {children}
    </p>
  )
}

export const UploadFileBtn = ({ children, ...props }) => {
  return (
    <button
      {...props}
      className={
        'rounded-full my-2 text-sm border-cyan-500 border-2 px-4 py-1 text-cyan-600 uppercase max-w-max'
      }
    >
      {children}
    </button>
  )
}

export const FilePreviewContainer = ({ children, className, ...props }) => {
  return (
    <article {...props} className={className}>
      {children}
      {children &&
        children.props &&
        children.props.children &&
        children.props.children.length > 0 &&
        // <span>Selected images: {children.props.children.length}</span>
        null}
    </article>
  )
}

export const PreviewList = ({ children, ...props }) => {
  return (
    <section {...props} className={'flex overflow-x-auto gap-4'}>
      {children}
    </section>
  )
}

export const PreviewContainer = ({ children, ...props }) => {
  return (
    <section {...props} className={'relative space-x-2 min-w-max '}>
      {children}
    </section>
  )
}

export const ImagePreview = ({ alt, className, ...props }) => {
  return (
    <img
      {...props}
      alt={alt || 'Preview'}
      className={'size-20 my-2 aspect-square object-cover relative ' + className}
    />
  )
}

FileUploadContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
InputLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
DragDropText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
UploadFileBtn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
FilePreviewContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
PreviewList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
PreviewContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
ImagePreview.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  alt: PropTypes.string,
}
