import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import FileUpload from '../components/form/FileUpload'
import Map from '../components/homepage/Map'
import InputField from '../components/form/InputField'
import FormWrapper from '../components/form/FormWrapper'
import useFormState from '../components/form/hooks/useFormState'
import HaveQuestions from '../components/StillHaveQuestions'
import CustomerRepairLetters from '../components/repairs/CustomerRepairLetters'
import isEmail from '../components/form/validators/isEmail'
import isPhone from '../components/form/validators/isPhone'

const EZShipBox = () => {
  const [form, setForm, updateForm] = useFormState({
    name: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    item_type: '',
    photos: '',
    zip: '',
    consent: true,
  })
  const updateUploadedFiles = React.useCallback(files => {
    setForm(f => ({ ...f, photos: files }))
  })
  const onChangeHandler = e => {
    setForm(f => ({ ...f, [e.target.name]: e.target.checked }))
  }
  return (
    <Layout className={'mx-auto'} breadcrumbs={false} canonical={'/ez-ship-box-request-form/'}>
      <Seo
        title="EZ Ship Box Request Form | www.grayandsons.com"
        description="Fill out this form to receive all the supplies necessary to ship your watch or jewelry for an accurate offer."
        canonical={'/ez-ship-box-request-form/'}
      />

      <section className="w-10/12 max-w-[1366px] mx-auto py-10">
        <h1 className="text-center underline-title text-xl uppercase my-5">
          REQUEST AN EZ SHIP BOX TODAY!
        </h1>
        <p className="text-center max-w-[800px] mx-auto">
          Fill out this simple form to receive all necessary supplies to send us the items you want
          to repair. Please note, the customer is responsible for "insured return shipping payment"
          regardless of whether they approve the repair quote.
        </p>
        <div className="w-full flex flex-col xl:flex-row gap-5 py-5">
          <div className="w-full flex justify-start aspect-video xl:aspect-square">
            <StaticImage
              src={'../images/ez-ship-box-form-header.png'}
              alt={'EZ Ship Box Supplies Provided'}
              className={'object-contain'}
              loading={'eager'}
            />
          </div>
          <div className="flex w-full flex-col">
            <FormWrapper
              className={''}
              name={'EZ Ship Box'}
              action={'/thank-you-ez-ship-box/'}
              form={form}
              conversionEventName="ez_ship_box"
            >
              <div className="flex flex-col space-y-4">
                <InputField
                  name={'name'}
                  label={'Name'}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={'phone'}
                  label={'Phone'}
                  form={form}
                  validator={isPhone}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={'email'}
                  label={'E-Mail'}
                  form={form}
                  type={'email'}
                  validator={isEmail}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={'address1'}
                  label={'Street Address'}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={'address2'}
                  label={'Address Line 2'}
                  form={form}
                  onChange={updateForm}
                />
                <span className="grid grid-cols-4 gap-4">
                  <InputField
                    name={'city'}
                    label={'City'}
                    form={form}
                    onChange={updateForm}
                    className={'col-span-4 sm:col-span-2 lg:col-span-3'}
                    required={true}
                  />
                  <InputField
                    name={'state'}
                    label={'State'}
                    form={form}
                    onChange={updateForm}
                    className={'sm:col-span-2 lg:col-span-1 col-span-4'}
                    required={true}
                  />
                </span>
                <InputField
                  name={'item_type'}
                  type={'textarea'}
                  rows="4"
                  label={'What are you looking to sell?'}
                  form={form}
                  onChange={updateForm}
                />
                <label className="text-xs text-gray-500 flex flex-row gap-3 items-start my-2">
                  <input
                    type="checkbox"
                    name="consent"
                    checked={form['consent']}
                    onChange={onChangeHandler}
                  />
                  I agree to receive updates and follow-up messages about my inquiries via text and
                  email.
                </label>
                <br />
                <FileUpload
                  id={'photos'}
                  label={'Add photos'}
                  name={'photos'}
                  accept={'.jpg,.png,.jpeg'}
                  updateFilesCb={updateUploadedFiles}
                  multiple
                />
                * Max. file size: 40 MB, Max. files: 5.
                <br />
                <br />
                Please attach photos of items & appraisals/certificates. The red 'X's are normal.
              </div>
              <br />
              <br />
              <button className="bg-red-700 text-white px-8 py-3 block mx-auto lg:mx-0">
                SUBMIT FORM
              </button>
            </FormWrapper>
          </div>
        </div>
      </section>

      <CustomerRepairLetters />
      <HaveQuestions />
      <section className={'mt-16'}>
        <Map />
      </section>
    </Layout>
  )
}
EZShipBox.propTypes = {
  location: PropTypes.object,
}

export default EZShipBox
